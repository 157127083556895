/** global styles **/

@font-face {
  font-family: 'DINNextLTPro';
  src: url('./font/DINNextLTPro-Regular.woff2') format('woff2'),
    url('./font/DINNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@media print {
  @page {
    size: A4,
  }

  #ScenarioDisplay {
    width: '190mm';
  }

}


body {
  color: #2c3f4d !important;
}

/** global styles **/
* {
  font-family: 'DINNextLTPro';
}

/** fix selects */
.MuiPopover-root {
  position: absolute !important;

  .MuiPopover-paper {
    top: 0px !important;
    left: 0px !important;
    max-height: 20vh !important;
    max-width: none !important;
  }
}

/* icons */
.MuiSvgIcon-root {
}

.MuiSelect-icon,
.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  fill: transparent !important;
  background: url(./images/expand-more-24-px.svg) no-repeat center center;
  background-size: contain;
  
  @media print {
    background: white;
  }
}

.MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
  fill: transparent !important;
  background: url(./images/expand-more-24-px.svg) no-repeat center center;
  background-size: contain;
  
}

/** charts **/
tspan {
  user-select: none;
}

.future-slider {
  margin: 0;
}

// ScenarioGraph bars
.bars {
  display: relative;
  height: 120px;
  // margin: 30px 20px 20px 55px;
  &.trachoma {
  }
  &.setup {
    margin-left: 0;
    margin-top: 30px;
    height: auto;
    margin-bottom: 7px;
    justify-content: flex-start;
    display: flex;
    position: relative;
  }

  .stop-color-0 {
    stop-color: #008dc9;
  }
  .stop-color-100 {
    stop-color: #90CDE7;
  }


  .bar {
    position: absolute;
    z-index: 9;
    height: 110px;
    min-width: 1px;
    border: 1px solid white;
    cursor: pointer;
    &.setup {
      position: relative;
      border: none;
      margin-right: 15px;
      cursor: help;
    }
    &.history {
      .stop-color-0 {
        stop-color: #CFD9DD;
      }
      .stop-color-100 {
        stop-color: #EFF0F1;
      }
    }

  
    &.current {
      z-index: 10;
    }
    &.removed {
      > span {
        .stop-color-0 {
          stop-color: #EFF0F1;
        }
        .stop-color-100 {
          stop-color: #EFF0F1;
        }
      }
    }

    > span {
      display: block;
      min-width: 10px;
      opacity: 1;
      &.current {
        .stop-color-0 {
          stop-color: #ffc914;
        }
      }
    }
    &.history {
      > span {
        display: block;
        min-width: 10px;
        opacity: 1;
        &.current {
          .stop-color-0 {
            stop-color: #d01c8b;
          }
        }
      }
    }
  }
}

.bar-tooltip {
  position: absolute;
  width: auto;
  height: 40px;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 5px;
  background: #008dc9;
  color: #fff;
  white-space: nowrap;
  padding: 5px 10px;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 0 14px;
    border-color: #008dc9 transparent transparent transparent;
    position: absolute;
    bottom: -14px;
    left: 50%;
    margin-left: -14px;
  }

  .t {
    display: inline-block;
    vertical-align: top;
    height: 30px;
    line-height: 32px;
    font-size: 16px;
  }

  .i {
    display: inline-block;
    vertical-align: top;
    height: 30px;
    width: 30px;
    margin-left: 1px;

    &.plus {
      background: url(./images/plus-icon.svg) no-repeat center center;
      background-size: contain;
    }

    &.edit {
      background: url(./images/edit-icon.svg) no-repeat center center;
      background-size: contain;
    }

    &.remove {
      background: url(./images/delete-icon.svg) no-repeat center center;
      background-size: contain;
    }

    &.halt {
      background: url(./images/halt-icon.svg) no-repeat center center;
      background-size: contain;
    }
  }
}

.MuiTooltip-popper {
  &#map-tooltip {
    .MuiTooltip-tooltip {
      font-size: 14px;
      padding: 10px;
    }
  }
}

.caps-text {
  & + .fwbg {
    margin-top: 20px;
  }
}
